<template>
  <div>
    <BaseForm
      :title="title"
      :mod="mod"
      :flat="flat"
      :divider="divider"
      :dialog="dialog"
      :loading="loading"
      @save="save"
    >
      <template #content>
        <v-row>
          <v-col>
            <v-text-field
              label="Nome"
              v-model="form.name"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              :items="[
                { text: 'Meses', value: 'months' },
                { text: 'Anos', value: 'years' },
              ]"
              label="Unidade de tempo"
              v-model="form.intervalUnit"
              class="required"
            ></v-select>
          </v-col>

          <v-col>
            <v-text-field
              label="Quantidade"
              v-model="form.interval"
              class="required"
              type="number"
              min="0"
              step="1"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Desconto (%)"
              v-model="form.discount"
              class="required"
              type="number"
              min="0"
              max="100"
              step="0.1"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template #actions></template>
    </BaseForm>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";

export default {
  components: {
    BaseForm,
  },
  mixins: [formMixin],
  data: () => ({
    loading: false,
    form: {},
    actionAfterOperation: null,
  }),
  computed: {},
  methods: {
    validate() {
      const errors = [];

      if (!this.form.name) errors.push("O campo NOME é obrigatório");

      if (!this.form.intervalUnit)
        errors.push("O campo UNIDADE DE TEMPO é obrigatório");

      if (!this.form.interval) errors.push("O campo QUANTIDADE é obrigatório");

      if (!this.form.discount) errors.push("O campo DESCONTO é obrigatório");

      if (this.form.discount < 0 || this.form.discount > 100)
        errors.push("O campo DESCONTO deve estar entre 0 e 100");

      return errors;
    },
    async save(actionAfterOperation) {
      if (
        !(await this.$root.$confirm("Confirmação", "Salvar dados?", {
          color: "primary",
        }))
      )
        return;

      const errors = this.validate();

      if (errors.length) {
        this.$root.$errorDialog(errors);
      } else {
        this.actionAfterOperation = actionAfterOperation;
        this[this.mod]();
      }
    },
    async insert() {
      try {
        this.loading = true;

        await this.$axios.post(`/time-intervals`, this.form);

        this.clearForm();

        this.$toast.success("Dados salvos com sucesso");

        this.afterOperation();
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        await this.$axios.put(
          `/time-intervals/${this.$route.params.id}`,
          this.form
        );

        this.$toast.success("Dados salvos com sucesso");

        this.afterOperation();
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async loadIntervalData() {
      try {
        this.loadingPermissions = true;

        const response = await this.$axios.get(
          `/time-intervals/${this.$route.params.id}`
        );

        this.setIntervalData(response.data);
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loadingPermissions = false;
      }
    },
    setIntervalData(data) {
      const cpData = { ...data };

      this.form = cpData;
    },
    clearForm() {
      this.form = {};
    },
    afterOperation() {
      if (this.actionAfterOperation === "back") this.$router.go(-1);
    },
  },
  created() {
    if (this.mod === "update") this.loadIntervalData();
  },
};
</script>

<style></style>
